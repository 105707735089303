import { Component, BaseComponent, Prop } from '@zento-lib/components';

import type { CategoryTypes } from '../types/CategoryTypes';
import type { LocalizedRoute } from '../types/RouteTypes';

import type { ILink } from './Link.d';
import style from './style.scss';

// Defines the different style types for link
export type LinkStyleType = 'primary' | 'secondary';

/**
 * Link
 *
 * Link component allowing to enable user navigation in a router-enabled app.
 * The target location is specified with the 'to' property
 **/
@Component({})
export class Link extends BaseComponent<ILink, unknown> {
  /**
   * Denotes the target route of the link. When clicked, the value of the to prop will be passed to
   * router.push() internally, so the value can be either a string or a location descriptor object.
   */
  @Prop({ type: [String, Object], required: true })
  to: string | { path: string; message?: string } | CategoryTypes | LocalizedRoute;

  /**
   * A value which will render a different css
   */
  @Prop({ type: String, default: 'primary' })
  styleType?: LinkStyleType;

  /**
   * Determines link title
   */
  @Prop({ type: String })
  title?: string;

  render() {
    return this.to ? (
      <router-link
        to={this.extended.localizedRoute(this.to)}
        title={this.title}
        class={{
          [style.link]: true,
          [style.normalLink]: this.styleType === 'primary',
          [style.secondaryLink]: this.styleType === 'secondary',
        }}>
        <slot />
      </router-link>
    ) : null;
  }
}
